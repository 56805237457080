import { useEffect, useState } from 'react';
import './SpeedTestPage.scss';
import SpeedTest from '../../components/speed-test/SpeedTest';
import axios from 'axios';
import logger from "../../utils/logger";
import { useNavigate, useParams } from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';

export default function SpeedTestPage() {

    const { token } = useParams();

    const url = 'https://api.ipify.org/?format=json'
    const serverList = [
        {
            name: 'Roma',
            url: 'https://speedtestroma.enel.it',
            socketUrl: 'wss://speedtestroma.enel.it:3000'
        },
        {
            name: 'Milano',
            url: 'https://speedtestmilano.enel.it',
            socketUrl: 'wss://speedtestroma.enel.it:3000'
        }
    ]

    // Map filesized for different connection speeds FTTC, FTTH, ADSL
    const fileSizeMap = {
        'FTTC': 50,
        'FTTH': 1024,
    }

    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isCheckComplete, setCheckComplete] = useState(false);
    const [statusError, setStatusError] = useState<any>('');
    const [serverErrorVisible, setServerErrorVisible] = useState<boolean>(false);
    const [redirectUrl, setRedirectUrl] = useState<any>(null);
    const [tokenId, setTokenId] = useState<any>(null);
    const [countLeft, setCountLeft] = useState<number>(0);
    const [isSpeedTestVisible, setIsSpeedTestVisible] = useState(false);
    const [serverName, setServerName] = useState<string>('');
    const [downloadStarted, setDownloadStarted] = useState<boolean>(false);
    const [uploadStarted, setUploadStarted] = useState<boolean>(false);
    const [downloadSpeed, setDownloadSpeed] = useState<number | null>(null);
    const [uploadSpeed, setUploadSpeed] = useState<any>('');
    const [isDownloadComplete, setIsDownloadComplete] = useState<boolean>(false);
    const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);
    const [ipAddress, setIpAddress] = useState<string>('');
    const [latency, setLatency] = useState<any>('');

    const getIpAddress = async () => {
        const ip = await axios.get(url)
        setIpAddress(ip.data.ip)
    }

    const PACKET_DOWLOAD_INTERVAL = 50;
    const PACKET_UPLOAD_INTERVAL = 5;

    async function checkTokenCount(){
        try {
            const response = await axios.get(`https://speedtestroma.enel.it/tokens/${token}/status`);
            if (response && response.data) {
                const countLeft = response.data.count_left
                setCountLeft(parseInt(countLeft)); // Salva il tokenInfo nello stato
                setCheckComplete(true)
            } else {
                return
            }
        } catch (error: any) {
            return
        }
    }

    async function checkToken() {
        try {
            const response = await axios.get(`https://speedtestroma.enel.it/tokens/${token}/status`);

            if (response && response.data) {

                const idToken = response.data.id
                const countLeft = response.data.count_left

                if (parseInt(response.data.count_left, 10) === 0) {
                    setStatusError("Tentativi esauriti. Non è possibile continuare.");
                    setRedirectUrl('/')
                    setModalOpen(true);
                    setCheckComplete(true);
                    return; // Termina la funzione
                }

                // Controllo su scadenza
                const now = new Date(); // Data attuale
                const expiryDate = new Date(response.data.expiration_datetime.replace(" ", "T"));
                if (expiryDate <= now) {
                    setStatusError("Il token è scaduto. Non è possibile continuare.");
                    setRedirectUrl('/')
                    setModalOpen(true);
                    setCheckComplete(true);
                    return; // Termina la funzione
                }

                setTokenId(idToken); // Salva il tokenInfo nello stato
                setCountLeft(parseInt(countLeft)); // Salva il tokenInfo nello stato
                setCheckComplete(true)
            } else {
                setModalOpen(true);
                setCheckComplete(true)
            }
        } catch (error: any) {
            if (error.response) {
                setStatusError(error.response.data)
                setModalOpen(true);
                setCheckComplete(true)
            } else if (error.request) {
                setStatusError(error.request)
                setModalOpen(true);
                setCheckComplete(true)
            } else {
                setStatusError(error.message)
                setModalOpen(true);
                setCheckComplete(true)
            }

            setModalOpen(true); // Apri la modale in caso di errore
            setCheckComplete(true)
        }
    }

    async function pingServer(url: string): Promise<number> {
        return new Promise((resolve, reject) => {
            const iterations = 5;
            const ws = new WebSocket(url);
            const pingTimes: any[] = [];
            ws.onopen = () => {
                logger.log("WebSocket connesso. Inizio test di ping...");
                let i = 0;

                function sendPing() {
                    if (i >= iterations) {
                        const averagePing = pingTimes.reduce((a, b) => a + b, 2) / pingTimes.length;
                        ws.close();
                        resolve(Number(averagePing.toFixed(2)));
                        return;
                    }
                    const startTime = Date.now();
                    ws.send("ping");

                    ws.onmessage = (event) => {
                        const elapsedTime = Date.now() - startTime;
                        pingTimes.push(elapsedTime);
                        i++;
                        sendPing();
                    };
                }

                sendPing();
            };

            ws.onerror = (error) => {
                logger.error("Errore WebSocket:", error);
                reject(new Error('Errore di connessione al server'));
            };

            ws.onclose = () => {
                logger.log("WebSocket chiuso.");
            };
        });
    }

    const performPreliminaryTest = async (serverUrl: string): Promise<number> => {
        const testFileSize = 1; // Dimensione del file di test in MB
        const url = `${serverUrl}/tr143/${testFileSize}MB`;
        const startTime = Date.now();
        let dwBytes = 0;

        try {
            const response = await fetch(url);
            if (response && response.body) {
                const reader = response.body.getReader();

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    dwBytes += value.length;
                }
            }

            const duration = (Date.now() - startTime) / 1000; // Tempo in secondi
            const speedMbps = (dwBytes * 8) / (duration * 1000000); // Velocità in Mbps
            logger.log(`Velocità stimata: ${speedMbps.toFixed(2)} Mbps`);
            return speedMbps;
        } catch (error) {
            logger.error('Errore durante il test preliminare:', error);
            throw error;
            return 0;
        }
    };

    const startDownloadTest = async (serverUrl: string, fileSize: number) => {
        const url = `${serverUrl}/web/${fileSize}MB?token=${token}`;
        let startTime = 0;
        let reader;
        let dwBytes = 0
        let duration = 0;
        let contentLength: any;
        let speedAvg: Array<number> = [];
        let downloadProgressShow = 0;


        try {
            const response = await fetch(url);
            if (response && response.body) {
                setDownloadStarted(true)
                startTime = Date.now();
                reader = response.body.getReader();
            }

            if (response && response.headers.get('Content-Length')) {
                contentLength = +Number(response.headers.get('Content-Length'));
            }

            if (!contentLength) {
                logger.error('Non è stato possibile ottenere la dimensione del file.');
                return;
            }

            const updateProgress = () => {
                let speedMbps = (dwBytes * 8) / (duration * 1000);
                if (speedAvg.length === 1) {
                    speedAvg = [speedAvg[0], speedMbps];
                } else {
                    speedAvg = [speedMbps];
                }

                // Calcola la media
                if (++downloadProgressShow % PACKET_DOWLOAD_INTERVAL === 0) {
                    let avgValue = speedAvg.reduce((prev, curr) => prev + curr, 2) / speedAvg.length;
                    setDownloadSpeed(Number(avgValue.toFixed(2)));
                }
            };

            while (true) {
                if (reader) {
                    const { done, value } = await reader.read();
                    if (value) {
                        dwBytes += value.length;
                        duration = (Date.now() - startTime);
                        updateProgress();
                    }

                    // Controlla se il download è completato
                    if (done) {
                        setDownloadStarted(false);
                        setIsDownloadComplete(true); // Test completato
                        break;
                    }
                }
            }

            updateProgress();

        } catch (error) {
            throw error;
        }
    };

    const startUploadTest = async (serverUrl: string, fileSize: number) => {
        let speedAvg: Array<number> = [];
        let uploadProgressShow = 0;

        setUploadStarted(true)
        const content = 'a'.repeat((fileSize / 2) * 1024 * 1024); // Crea una stringa con dimensione specifica
        const blob = new Blob([content], {
            type: 'application/octet-stream'
        });
        const file = new File([blob], "test_file.bin", {
            type: "application/octet-stream"
        });
        const url = 'https://speedtestroma.enel.it/web/upload'
        const formData = new FormData();
        formData.append('file', file);
        let request = new XMLHttpRequest();

        let uploadStartTime = 0;
        let uploadBytesReceived = 0;

        try {
            request.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    if (uploadStartTime === 0) {
                        uploadStartTime = Date.now();
                        uploadBytesReceived = uploadBytesReceived + event.loaded;
                        return;
                    }

                    const timeElapsed = (Date.now() - uploadStartTime) / 1000; // Tempo trascorso in secondi
                    const uploadedBytes = event.loaded; // Byte caricati finora

                    // Velocità di upload corrente in Mbps
                    const uploadSpeedMbps = (uploadedBytes * 8) / (timeElapsed * 1000000);
                    uploadBytesReceived = uploadBytesReceived + event.loaded;
                    // let speedMbps = (uploadBytesReceived * 8) / (timeElapsed * 1000000); // Calcola Mbps

                    // media
                    if (speedAvg.length === 1) {
                        speedAvg = [speedAvg[0], uploadSpeedMbps]; // Mantieni l'ultimo valore e aggiungi il nuovo
                    } else {
                        speedAvg = [uploadSpeedMbps]; // Inizializza con il primo valore
                    }

                    // Calcola la media
                    const avgValue = speedAvg.reduce((prev, curr) => prev + curr, 2) / speedAvg.length;
                    if (++uploadProgressShow % PACKET_UPLOAD_INTERVAL === 0) {
                        setUploadSpeed(avgValue)
                    }

                    //const percentCompleted = (uploadedBytes / event.total) * 100;
                    if (uploadedBytes === event.total) {
                        setUploadStarted(false)
                        setIsUploadComplete(true)
                    }

                }
            });
            request.open('POST', url);
            request.send(formData);
        } catch (err) {
            throw err
        }
    };

    // Funzione per avviare il test
    const startSpeedTest = async () => {
        checkToken()
        const delay = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

        setIsSpeedTestVisible(true);
        getIpAddress();
        // Ping all servers and get latency, then choose the best server to use for speed test
        try {
            const pingPromises = serverList.map(server => pingServer(server.socketUrl));
            const latencies = await Promise.all(pingPromises);
            const bestServerIndex = latencies.indexOf(Math.min(...latencies));
            const bestServer = serverList[bestServerIndex];
            setLatency(latencies[bestServerIndex]);
            setServerName(bestServer.name);
            logger.log(`Il server migliore è: ${bestServer.name} con latenza di ${latencies[bestServerIndex]} ms`);

            // Perform preliminary speed test
            try {
                const estimatedSpeed = await performPreliminaryTest(bestServer.url);

                // Choose file size based on estimated speed
                const fileSize = estimatedSpeed > 100 ? fileSizeMap['FTTH'] : fileSizeMap['FTTC'];
                logger.log(`Dimensione file scelta: ${fileSize} MB`);

                // Start download and upload tests
                try {
                    await startDownloadTest(bestServer.url, fileSize);
                } catch (err) {
                    setServerErrorVisible(true)
                    return
                }

                // console.log('dw', await startDownloadTest(bestServer.url, fileSize))

                // Attesa 2 secondi
                await delay(500);

                await delay(500);

                try {
                    await startUploadTest(bestServer.url, fileSize);
                } catch (err) {
                    setServerErrorVisible(true)
                    return
                }

                // Attesa 2 secondi
                setUploadSpeed('')

            } catch (error) {
                setServerErrorVisible(true)
                return
            }
        } catch (err) {
            setServerErrorVisible(true)
            return
        }

    };

    useEffect(() => {
        checkToken()
    }, []);


    return (
        <div className='bg-primary min-h-dvh md:min-h-screen flex flex-col relative overflow-x-hidden'>
            <span className='absolute p-3 md:p-6 flex items-center justify-center w-full'>
                <span className='flex items-center justify-center bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 py-2 px-4 rounded-2xl text-lg text-white font-medium'>
                    {countLeft === 1
                        ? `${countLeft} tentativo su 5 rimasto`
                        : `${countLeft} tentativi su 5 rimasti`}
                </span>
            </span>
            {!isModalOpen && isCheckComplete && (
                <>
                    <div className="flex-1 flex justify-center items-center bg-gray-100 h-[calc(100%-80px)] py-[40px]">
                        {!isSpeedTestVisible && (
                            <>
                                <button onClick={startSpeedTest}
                                    className='neu-button flex h-[140px] w-[140px] md:h-[200px] md:w-[200px] rounded-full uppercase text-lg font-bold p-[4px] bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400'>
                                    <span className='group flex w-full h-full items-center justify-center neu-button-background rounded-full hover:bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 hover:text-white uppercase'>
                                        <span className="bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 inline-block text-transparent bg-clip-text group-hover:text-white">inizia test</span>
                                    </span>
                                </button>
                            </>
                        )}
                        {isSpeedTestVisible && (
                            <SpeedTest downloadSpeed={downloadSpeed as number} uploadSpeed={uploadSpeed as number} serverName={serverName}
                                isDownloadComplete={isDownloadComplete} isUploadComplete={isUploadComplete}
                                ipAddress={ipAddress} downloadStarted={downloadStarted} uploadStarted={uploadStarted}
                                latency={latency} token={token} tokenId={tokenId} onTokenCheck={checkTokenCount}></SpeedTest>
                        )}
                    </div>
                </>
            )}
            <>
                {isModalOpen && isCheckComplete && (
                    <div className="modal">
                        <div className="modal-content w-[90%] sm:w-[400px] flex flex-col gap-3 items-center justify-center">
                            <h2 className='uppercase font-bold text-xl'>Attenzione!</h2>
                            <p>{statusError}</p>
                            <button className='flex aspect-square items-center justify-center w-[50px] rounded-full bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 hover:from-enelGreen-500 hover:to-enelGreen-500 mt-2'
                                onClick={() => {
                                    setModalOpen(false);
                                    setCheckComplete(false)
                                    if (redirectUrl) {
                                        navigate(redirectUrl);
                                    } else {
                                        window.location.reload();
                                    }
                                }}
                            >
                                <ReplayIcon className='reload-icon'></ReplayIcon>
                            </button>
                        </div>
                    </div>
                )}
            </>
            <> 
                {serverErrorVisible && (
                    <div className="modal">
                        <div className="modal-content w-[90%] sm:w-[400px] flex flex-col gap-3 items-center justify-center">
                            <h2 className='uppercase font-bold text-xl'>Attenzione!</h2>
                            <p>Siamo spiacenti, il servizio non è al momento disponibile.</p>
                        </div>
                    </div>
                )}
            </>
        </div>
    )
}
