import { useEffect, useState } from 'react';
import './SpeedTest.scss';
import GaugeComponent from 'react-gauge-component';
import { CircularProgress } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import NetworkPingIcon from '@mui/icons-material/NetworkPing';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import DoneIcon from '@mui/icons-material/Done';
import axios from 'axios';
import logger from '../../utils/logger';

export default function SpeedTest(
    {
        downloadStarted,
        uploadStarted,
        serverName,
        downloadSpeed,
        uploadSpeed,
        isDownloadComplete,
        isUploadComplete,
        ipAddress,
        latency,
        token,
        tokenId,
        onTokenCheck
    }: {
        downloadStarted: boolean,
        uploadStarted: boolean,
        serverName: string,
        downloadSpeed: number,
        uploadSpeed: number,
        isDownloadComplete: boolean,
        isUploadComplete: boolean,
        ipAddress: string
        latency: any,
        token: string | undefined,
        tokenId: string | undefined,
        onTokenCheck: () => Promise<void>;
    },
) {

    const [loading, setLoading] = useState(false);

    const postTestResults = async () => {
        let results : {id_token: string | undefined, server_location: string, download?: string, upload?: string} = {
            id_token: tokenId,
            // download: downloadSpeed.toFixed(2),
            // upload: uploadSpeed.toFixed(2),
            server_location: serverName
        };

        if(downloadSpeed){
            results.download = downloadSpeed.toFixed(2)
        }

        if(uploadSpeed){
            results.upload = uploadSpeed.toFixed(2)
        }

        try {
            const response = await axios.post('https://speedtestroma.enel.it/tokens/result', results);
            if (onTokenCheck) {
                await onTokenCheck();
            }
            logger.log('Risultati del test inviati con successo:', response.data);
        } catch (error) {
            logger.error('Errore durante l\'invio dei risultati del test:', error);
        }
    };

    useEffect(() => {
        const shouldLoad =
            (downloadStarted && !isDownloadComplete) ||
            (uploadStarted && !isUploadComplete);

        setLoading(shouldLoad);
    }, [downloadStarted, isDownloadComplete, uploadStarted, isUploadComplete]);

    useEffect(() => {
        if (isUploadComplete) {
            postTestResults()
        }
    }, [isUploadComplete]);

    const kbitsToMbits = (value: number) => {
        if (value === 0)
            return "";

        if (value >= 1) {
            if (Number.isInteger(value)) {
                return value.toFixed(2);
            } else {
                return value.toFixed(2);
            }
        } else {
            value = value * 1000;
            return value.toFixed(2);
        }
    }

    const percentageToPlain = (value: number) => {
        return `${value}`;
    }

    return (
        <div className='flex flex-col justify-center items-center gap-3 px-3 w-full md:w-[900px]'>
            <div className='flex mb-6 h-[72px] w-full neu-card py-3 px-5 rounded-xl gap-3 border-[3px] border-white justify-center items-center'>Speed Test</div>
            <GaugeComponent
                // type="radial"
                className='w-[400px] md:w-[600px]'
                arc={{
                    nbSubArcs: 100,
                    cornerRadius: 10,
                    width: 0.15,
                    colorArray: uploadStarted || uploadSpeed ? ['#419141', '#55be5a', '#62e07a'] : ['#2a79a7', '#41b9e6', '#62c7f3'],
                    // width: 0.3,
                    padding: 0,
                    emptyColor: '#ededed',
                    subArcs: [
                        {
                            limit: 25,
                            // color: '#0047CC',
                            showTick: true
                        },
                        {
                            limit: 50,
                            // color: '#41b9e6',
                            showTick: true
                        },
                        {
                            limit: 75,
                            // color: '#00DFFF',
                            showTick: true
                        },
                        {
                            limit: 100,
                            // color: '#72FF79',
                            showTick: true
                        },
                    ]
                }}
                pointer={
                    {
                        type: "arrow",
                        color: '#345243',
                        length: 0.80,
                        width: 15,
                    }

                }
                labels={{
                    valueLabel: {
                        style: {
                            fontFamily: 'Gauge Mono Regular Regular',
                            fontWeight: window.innerWidth <= 768 ? 500 : 700,
                            fontSize: 40,
                            textShadow: 'none',
                            padding: '10px',
                            display: 'flex',
                            fill: 'black',
                            letterSpacing: '-2px'
                        },
                        formatTextValue: kbitsToMbits
                    },
                    tickLabels: {
                        type: "outer",
                        ticks: [
                            { value: 25 },
                            { value: 50 },
                            { value: 75 },
                        ],
                        defaultTickValueConfig: {
                            formatTextValue: percentageToPlain,
                            style: {
                                fontSize: '18px',
                                fontFamily: 'Gauge Mono Regular Regular',
                                fontWeight: 'bold',
                                fill: '#333333',
                                padding: '30px',
                            }
                        },
                        defaultTickLineConfig: {
                            hide: true
                        }
                    }
                }}
                value={isDownloadComplete ? uploadSpeed : downloadSpeed}
            />
            <div className='grid grid-cols-1 sm:grid-cols-4 font-primary gap-3 w-full mt-2'>
                <span className='col-span-2 flex neu-card rounded-xl p-[3px] items-center !bg-gradient-to-bl from-enelLightBlue-200 to-enelLightBlue-600'>
                    <span className={
                        `col-span-2 w-full flex py-3 px-5 rounded-[10px] gap-3 items-center ${isDownloadComplete && downloadSpeed ? '!bg-downloadSpeed-to-bl from-enelLightBlue-200 to-enelLightBlue-600'
                            : 'bg-[#eff0f5]'
                        }`
                    }>
                        <div className={`p-3 flex rounded-full neu-icon ${isDownloadComplete && downloadSpeed
                            ? 'text-white bg-enelLightBlue-300 !shadow-none' : 'text-enelLightBlue-600 bg-[#eff0f5]'
                            }`}>
                            <DownloadIcon></DownloadIcon>
                        </div>
                        <span className='flex items-center justify-between w-full gap-2'>
                            <span className='uppercase text-sm'>Download</span>
                            {isDownloadComplete && downloadSpeed ? (
                                <span className='text-white text-2xl font-primary font-bold'>{downloadSpeed.toFixed(2)} <span className='text-lg font-normal'>Mbps</span></span>
                            ) : (
                                <span>--</span>
                            )}
                        </span>
                    </span>
                </span>
                <div className='col-span-2 flex neu-card rounded-xl p-[3px] items-center !bg-gradient-to-bl from-enelGreen-200 to-enelGreen-500'>
                    <span className={
                        `col-span-2 w-full flex py-3 px-5 rounded-[10px] gap-3 items-center ${isUploadComplete && uploadSpeed ? '!bg-gradient-to-bl from-enelGreen-200 to-enelGreen-500'
                            : 'bg-[#eff0f5]'
                        }`
                    }>
                        <div className={`p-3 flex rounded-full neu-icon ${isUploadComplete && uploadSpeed
                            ? 'text-white bg-enelGreen-300 !shadow-none' : 'text-enelGreen-500 bg-[#eff0f5]'
                            }`}>
                            <UploadIcon></UploadIcon>
                        </div>
                        <span className='flex items-center justify-between w-full gap-2'>
                            <span className='uppercase text-sm'>Upload</span>
                            {isUploadComplete === true && uploadSpeed ? (
                                <span className='text-white text-2xl font-primary font-bold'>{uploadSpeed.toFixed(2)} <span className='text-lg font-normal'>Mbps</span></span>
                            ) : (
                                <span>--</span>
                            )}
                        </span>
                    </span>
                </div>

            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 font-primary gap-3 w-full'>
            <span className='flex neu-card py-3 px-5 rounded-xl gap-3 border-[3px] border-white items-center'>
                    <div className='p-1 flex rounded-full neu-icon w-[48px] h-[48px] justify-center items-center'>
                        {loading ? (
                            <CircularProgress size={30} className={`w-[10px] ${uploadStarted ? '!text-enelGreen-500' : downloadStarted ? '!text-enelLightBlue-400' : 'text-gray-400'
                                }`}
                            />) : null
                        }
                        {loading === false && isUploadComplete === true ? (
                            <DoneIcon></DoneIcon>
                        ) : null }
                    </div>
                    <div>
                        <span className='flex flex-col items-start'>
                            <span className='text-sm uppercase'>Stato</span>
                            <span className="text-sm">
                                {downloadStarted ? (
                                    <span>Download...</span>
                                ) : uploadStarted ? (
                                    <span>Upload...</span>
                                ) : isUploadComplete ? (
                                    <span>Fatto!</span>
                                ) : (
                                    <span>Attesa...</span>
                                )}
                            </span>
                        </span>
                    </div>
                </span>
                <span className='col-span-1 flex neu-card py-3 px-5 rounded-xl gap-3 border-[3px] border-white items-center'>
                    <div className='p-3 flex rounded-full neu-icon'>
                        <NetworkPingIcon></NetworkPingIcon>
                    </div>
                    <span className='flex flex-col items-start'>
                        <span className='text-sm uppercase'>Ping</span>
                        <span className='text-sm font-bold'>{latency} ms</span>
                    </span>
                </span>
                <span className='flex neu-card py-3 px-5 rounded-xl gap-3 border-[3px] border-white items-center'>
                    <div className='p-3 flex rounded-full neu-icon'>
                        <PlaceIcon></PlaceIcon>
                    </div>
                    <span className='flex flex-col items-start'>
                        <span className='text-sm uppercase'>Server</span>
                        <span className='text-sm font-bold'>{serverName}</span>
                    </span>
                </span>
                <span className='flex neu-card py-3 px-5 rounded-xl gap-3 border-[3px] border-white items-center'>
                    <div className='p-3 flex rounded-full neu-icon'>
                        <NetworkPingIcon></NetworkPingIcon>
                    </div>
                    <span className='flex flex-col items-start'>
                        <span className='text-sm uppercase'>IP</span>
                        <span className='text-sm font-bold'>
                            {!ipAddress ? (
                                <span>
                                    --
                                </span>
                            ) : (
                                <span>
                                    {ipAddress}
                                </span>
                            )
                            }
                        </span>
                    </span>
                </span>
            </div>
        </div>
    )
}
