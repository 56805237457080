const isDevelopment = process.env.NODE_ENV === 'development';

interface Logger {
    log: (...args: any[]) => void;
    warn: (...args: any[]) => void;
    error: (...args: any[]) => void;
}

const logger: Logger = {
    log: (...args: any[]) => {
        if (isDevelopment) {
            console.log(...args);
        }
    },
    warn: (...args: any[]) => {
        if (isDevelopment) {
            console.warn(...args);
        }
    },
    error: (...args: any[]) => {
        console.error(...args); // Gli errori vengono sempre mostrati
    }
};

export default logger;
