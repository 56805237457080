import React from 'react';
import logo from './logo.svg';
import './App.css';
import SpeedTestPage from './pages/speed-test-page/SpeedTestPage';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import TokenPage from './pages/token-page/TokenPage';


function App() {
  return (
    <div className="App">
      <Router>
            <Routes>
                <Route path="/" element={<TokenPage />} />
                <Route path="/speed-test/:token" element={<SpeedTestPage />} /> 
            </Routes>
        </Router>
      {/* <SpeedTestPage></SpeedTestPage> */}
    </div>
  );
}

export default App;
